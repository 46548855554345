<template>
    <div class="box" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 0.8)">
        <div class="teb_header">
            <div class="search">
                <el-input placeholder="请输入用户姓名搜索" v-model="pname" :size="tabsize" class="input-search">
                </el-input>
                <el-input placeholder="请输入孩子姓名搜索" v-model="kname" :size="tabsize" class="input-search">
                </el-input>
                <el-input placeholder="请输入手机号搜索" v-model="phone" :size="tabsize" class="input-search">
                </el-input>
                <el-button slot="append" icon="el-icon-search" :size="tabsize" @click="handleSearch">搜索</el-button>
            </div>
        </div>

        <el-table :data="tableData" style="width: 100%;height:calc(100% - 90px);" stripe :size="tabsize"
            @sort-change="sortchange" :default-sort="{ prop: 'date', order: 'descending' }" border>
            <el-table-column type="selection" width="50"></el-table-column>
            <el-table-column label="序号" type="id" width="100" align='center'>
                <template slot-scope="scope">
                    <span>{{ scope.row.id }}</span>
                </template>
            </el-table-column>
            <el-table-column label="APP用户" prop="username" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.username }}</span>
                </template>
            </el-table-column>
            <el-table-column label="城市" prop="city" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.address }}</span>
                </template>
            </el-table-column>
            <el-table-column label="角色" prop="parents" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.parents }}</span>
                </template>
            </el-table-column>
            <el-table-column label="孩子" prop="kiscc" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.kiscc }}</span>
                </template>
            </el-table-column>

            <!-- <el-table-column label="视力" prop="sex" align="center">
                <template slot-scope="scope">
                     <span>{{ scope.row.answer }}</span>
                </template>
            </el-table-column> -->
            <el-table-column label="注册日期" prop="ctime" align="center">
                <template slot-scope="scope">
                    <span>{{ formatDate(scope.row.ctime) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="最近登录" prop="logindate" align="center">
                <template slot-scope="scope">
                    <span>{{ formatDate(scope.row.logindate) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="档案数" prop="kislogs" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.kislogs }}</span>
                </template>
            </el-table-column>

            <el-table-column label="操作" align="center" width="220">
                <template slot-scope="scope">
                    <el-button :size="tabsize" icon="el-icon-view" @click="handleEdit(scope.$index, scope.row)">查看
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.length"></el-pagination>
    </div>
</template>

<script>
    import dayjs from 'dayjs';
    export default {
        data() {
            return {
                pname: '', // 搜索内容
                kname: '', // 搜索内容
                phone: '', // 搜索内容
                tableData: [],
                currentPage: 1, //默认显示第一页
                pageSize: 40, //默认每页显示10条
                totalNum: '', //总页数
                ids: [],
                loading: false,
                dialogVisible: false,
                thisid: '',
                order: '',
                sort: '',
            }
        },
        mounted() { },
        methods: {
            formatDate(val) {
                return val ? dayjs(val).format("YYYY-MM-DD") : ''
            },
            updateList() {
                this.loading = true;
                this.$api.vip.updateList({
                    'jPage.pageNum': this.currentPage, //当前页码
                    'jPage.pageSize': this.pageSize, //当前页面大小
                    'jPage.sort': this.sort, //排序方式
                    'jPage.order': this.order, //排序字段
                    'pname': this.pname,
                    'kname': this.kname,
                    'phone': this.phone,
                }).then(res => {
                    this.tableData = res.obj.list //获取数据列表
                    this.totalNum = res.obj.totalPage // 总页数
                    this.tableData.length = res.obj.totalRow //总条数 
                    this.currentPage = res.obj.pageNumber //当前页码数
                    this.loading = false
                }).catch((error) => {
                    console.log(error);
                })
            },
            getconclusion() {

            },
            handleEdit(index, row) {
                let id = row.id
                this.$router.push({
                    path: '/vipinfo',
                    query: {
                        id
                    }
                })
            },
            // 分页大小
            handleSizeChange(val) {
                this.loading = true
                this.pageSize = val
                this.currentPage = 1 // 选择分页大小后跳转第一页
                this.updateList()
            },
            // 第几页
            handleCurrentChange(val) {
                this.loading = true
                this.currentPage = val
                this.updateList()
            },
            // 排序
            sortchange(val) {
                this.sort = val.prop
                if (val.order === 'descending') {
                    this.order = 'desc'
                } else if (val.order === 'ascending') {
                    this.order = 'asc'
                } else {
                    this.order = ''
                    this.sort = ''
                }
                this.loading = true
                this.updateList()
            },
            handleSearch() {
                this.updateList();
            }
        },
        // 监听尺寸按钮变化 改变表格大小
        computed: {
            tabsize() {
                return this.$store.state.tabsize
            },
            socketMsg() {
                return this.$store.state.socketMsg
            }
        },
        created() {
            this.loading = true
            this.updateList()
        },
    }
</script>

<style scoped>
    .box/deep/.el-table th.gutter {
        display: table-cell !important;
    }

    .box {
        position: relative;
        width: 98%;
        height: 98%;
        margin: 1%;
    }

    .teb_header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .input-search {
        width: 180px !important;
        margin-right: 15px;
    }
    

    .teb_header .el-input {
        width: auto;
        margin-left: 20px;
    }

    .el-table td {
        padding: 5px 0;
    }

    .el-pagination {
        float: right;
        margin-top: 10px;
    }

    .el-dialog__wrapper/deep/.el-dialog__header {
        text-align: center;
    }

    /deep/.el-table__body-wrapper {
        height: 98%;
        overflow-y: auto;
    }
</style>